








































































































import { defineComponent } from '@vue/composition-api'
import CancelButton from '@/components/buttons/CancelButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import { MyTenderDTO, ProcurementStatus } from '@/services/myTender/types'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import Timer from '@/components/Timer.vue'
import MyTenderService from '@/services/myTender'
import EditLotBids from '@/views/Tender/MyTender/EditLotBids.vue'
import { errorMessages, evaluationIntervals, EvaluationIntervals, isJsonString } from '@/utils/helpers'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { useErrors } from '@/components/composables/useErrors'
import { VueEditor } from 'vue2-editor'
import BlankModal from '@/components/BlankModal.vue'
import TenderDetailItem from '@/components/Tender/TenderDetailItem.vue'
import { LotBidDTO } from '@/services/tender/types'
import TenderService from '@/services/tender'
import DownloadButton from '@/components/buttons/DownloadButton.vue'

export default defineComponent({
  name: 'MyTenderOfferForm',
  components: {
    DownloadButton,
    SubmitButton,
    EditLotBids,
    Timer,
    CardTitle,
    CancelButton,
    TenderStatus,
    VueEditor,
    BlankModal,
    TenderDetailItem,
  },
  setup() {
    const { errors, clearFieldError } = useErrors()
    return { errors, clearFieldError, ProcurementStatus, TenderService }
  },
  data: function (): {
    id: string
    procurement: MyTenderDTO
    offerId: string
    viewType: string
    loading: boolean
  } {
    const id = 'id' in this.$route.params && this.$route.params.id
    const viewType = 'viewType' in this.$route.params && this.$route.params.viewType
    if (!id || !viewType) {
      throw new Error('MyTenderOfferForm is missing required route param id or viewType')
    }

    return {
      id,
      viewType,
      offerId: '',
      procurement: {
        termsAndConditions: '',
        name: '',
        capacity: 0,
        type: '',
        isPublishedAt: '',
        endsAt: '',
        status: '',
        cbcCoverage: true,
        crossBorderCapacityId: NaN,
        timeRemaining: 0,
        offer: {
          procurementId: 0,
          offeredCbc: 0,
          cbcDirection: '',
          lotBids: [],
        },
        lots: [],
        endTime: '',
        endDate: '',
      },
      loading: true,
    }
  },
  methods: {
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      this.loading = true
      try {
        const { data } = await MyTenderService.find(this.id)
        if (data) {
          const procurementData = data.data.procurement
          this.procurement = procurementData
          this.offerId = `${procurementData.offer.id}`

          this.procurement.offer.procurementId = this.procurement.id as number
          this.procurement.offer.lotBids.forEach((lotBid) => {
            if (lotBid.cbcDirection && isJsonString(lotBid.cbcDirection as string))
              lotBid.cbcDirection = JSON.parse(lotBid.cbcDirection as string)
            if (!lotBid.cbcDirection) lotBid.cbcDirection = []
          })
        }
      } finally {
        this.loading = false
        loader.hide()
      }
    },
    async save(): Promise<void> {
      await this.stringifyCbcDirections()
      const loader = this.$loading.show()
      try {
        const response = await MyTenderService.update(this.id, this.offerId, this.procurement.offer)
        if (response) {
          this.$toast.success(response.data.message)
          await this.redirectBack()
        }
      } catch (err) {
        if (err.response.data) {
          this.errors.value = err.response.data.errors || {}
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        await this.parseCbcDirections()
        loader.hide()
      }
    },
    async stringifyCbcDirections(): Promise<void> {
      this.procurement.offer.lotBids.forEach((lotBid: LotBidDTO) => {
        if (lotBid.cbcDirection && !isJsonString(lotBid.cbcDirection as string))
          lotBid.cbcDirection = JSON.stringify(lotBid.cbcDirection)
      })
    },
    async parseCbcDirections(): Promise<void> {
      this.procurement.offer.lotBids.forEach((lotBid: LotBidDTO) => {
        if (lotBid.cbcDirection && isJsonString(lotBid.cbcDirection as string))
          lotBid.cbcDirection = JSON.parse(lotBid.cbcDirection as string)
      })
    },
    async redirectBack(): Promise<void> {
      const { redirectedFrom } = this.$route.query
      await this.$router.push({ name: redirectedFrom ? (redirectedFrom as string) : 'MyTenders' })
    },
  },
  computed: {
    evaluationIntervals(): EvaluationIntervals {
      return evaluationIntervals(
        this.procurement.endTime,
        this.procurement.endDate,
        this.procurement.offerValidityHours || 0,
        this.procurement.offerValidityMinutes || 0,
      )
    },
    showSubmitButton(): boolean {
      return (
        this.procurement.status === ProcurementStatus.PUBLISHED &&
        !!this.procurement.timeRemaining &&
        this.viewType !== 'show'
      )
    },
  },
  mounted() {
    this.fetchItem()
  },
})
