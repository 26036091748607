


























































































































































import { defineComponent } from '@vue/composition-api'
import { LotBidDTO, LotDTO } from '@/services/tender/types'
import { formatDate, renderDeliveryProfile } from '@/utils/helpers'
import { VueEditor } from 'vue2-editor'
import { ingoingDirection, outgoingDirection } from '@/constants/capacityDirections'
import { BidStatus } from '@/enums/BidStatus'
import LotWinningStatus from '@/views/Tender/partials/LotWinningStatus.vue'

export default defineComponent({
  name: 'EditLotBids',
  setup() {
    return { formatDate, renderDeliveryProfile, ingoingDirection, outgoingDirection, BidStatus }
  },
  components: { LotWinningStatus, VueEditor },
  props: {
    readonly: { type: Boolean, default: false },
    tenderType: { type: String, required: true },
    lotBids: { type: Array as () => LotBidDTO[], required: true },
    lots: { type: Array as () => LotDTO[], required: true },
    errors: { type: Object, default: {} },
  },
  model: { prop: 'lotBids' },
  computed: {
    computedLotBids: {
      get(): LotBidDTO[] {
        return this.lotBids
      },
      set(value: LotBidDTO[]): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    calculatedTotal(offeredPower: number, totalHours: number) {
      return offeredPower ? offeredPower * totalHours : 0
    },
    calculatedOfferedPrice(pricePerUnit: number, quantity: number, key: number) {
      const offeredPrice = pricePerUnit ? pricePerUnit * quantity : 0
      this.computedLotBids[key].offeredPrice = offeredPrice
      this.setLotIdInLotBid(key)
      return offeredPrice
    },
    setLotIdInLotBid(key: number) {
      this.computedLotBids[key].lotId = this.lots[key].id
    },
    isWinnerLot(key: number) {
      return this.lotBids[key].isWinner && this.lotBids[key].status === BidStatus.CONFIRMED
    },
    isNotWinnerLot(key: number) {
      return !this.lotBids[key].isWinner && this.lotBids[key].status === BidStatus.NOT_APPROVED
    },
    isPending(key: number) {
      return this.lotBids[key].status === BidStatus.PENDING
    },
    clearFieldError(key: string) {
      this.$emit('clear-field-error', key)
    },
    setOfferedCBC(key: number) {
      if (!this.lots[key].cbcCoverage) this.computedLotBids[key].offeredCbc = this.computedLotBids[key].offeredPower
    },
    setOfferedPower(key: number) {
      this.computedLotBids[key].offeredPower = this.computedLotBids[key].offeredCbc as number
      this.computedLotBids[key].power = this.computedLotBids[key].offeredCbc as number
    },
    shouldDisplayStatus(key: number) {
      return this.isWinnerLot(key) || this.isNotWinnerLot(key)
    },
  },
})
